import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { sendCommentURI, uploadCVURI } from '../apis';
import { setValue } from './messageSlice';
// First, create the thunk
export const postComment = createAsyncThunk(
  'comments/sendComment',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(sendCommentURI, data);
      dispatch(setValue({ show: true, text: 'Leave a reply successfully' }));

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
const commentForm = JSON.parse(Cookies.get('comment-form') || '{}');
const initialState = {
  saving: 'idle',
  dirty: false,
  name: commentForm.name || '',
  email: commentForm.email || '',
  website: commentForm.website || '',
  comment: null || '',
  remember: commentForm.remember || '',
};
// Then, handle actions in your reducers:
const sendCommentSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    onChange: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
      state.dirty = true;
    },
  },
  extraReducers: {
    [postComment.pending]: (state, action) => {
      if (state.saving === 'idle') {
        state.saving = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    // Add reducers for additional action types here, and handle saving state as needed
    [postComment.fulfilled]: (state, action) => {
      state.comment = '';
      state.saving = 'idle';
      state.dirty = false;
    },
    [postComment.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.saving === 'pending' && state.currentRequestId === requestId) {
        state.saving = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});
export const { onChange } = sendCommentSlice.actions;
export default sendCommentSlice.reducer;
