/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchBlocksURI, fetchJobsURI } from '../apis';

// First, create the thunk
export const fetchRelatedJobs = createAsyncThunk(
  'related-jobs/fetchRelatedJobs',
  async (params = {}, thunkAPI) => {
    const { page = 1, limit = 8 } = params;
    const response = await axios.get(`${fetchJobsURI}?page=${page}&limit=${limit}`);
    return response.data;
  },
);

// Then, handle actions in your reducers:
const jobsSlice = createSlice({
  name: 'related-jobs',
  initialState: {
    entities: [],
    loading: 'idle',
    saving: 'idle',
    currentRequestId: undefined,
    error: null,
    currentPage: 1,
    totalPage: 1,
    total: 0,
  },
  reducers: {},
  extraReducers: {
    [fetchRelatedJobs.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchRelatedJobs.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        const { data, last_page, current_page, total } = action.payload;
        state.loading = 'idle';
        state.entities = data;
        state.currentPage = current_page;
        state.totalPage = last_page;
        state.currentRequestId = undefined;
        state.total = total;
      }
    },
    [fetchRelatedJobs.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});

export default jobsSlice.reducer;
