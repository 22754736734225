/* eslint-disable camelcase */
/* eslint-disable react/react-in-jsx-scope */
import { Jumbotron, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import contact_banner from '../../images/contact_banner.png';

const ContactUsBanner = ({ t }) => {
  return (
    <Jumbotron
      style={{
        backgroundImage: `url(${contact_banner})`,
        height: '383px',
        backgroundSize: 'cover',
      }}
      translate="no"
    >
      <Container>
        <p className="text-white text-uppercase">{t('contact_us_banner_title')}</p>
        <h1>
          <span className="text-white mr-1">{t('let_s_build_your')}</span>
          {t('system!')}
        </h1>
        <p>
          <Button
            variant="warning"
            className="text-uppercase font-weight-bold p-3"
            as={Link}
            to="/contact"
          >
            {t('contact_us')}
          </Button>
        </p>
      </Container>
    </Jumbotron>
  );
};

export default withTranslation()(ContactUsBanner);
