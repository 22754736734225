/* eslint-disable camelcase */
/* eslint-disable react/react-in-jsx-scope */
import { Jumbotron, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import contact_banner from '../../images/contact_banner.png';
import phone from '../../images/icons/phone.png';
import messenger from '../../images/icons/messenger.png';

const SocialIcons = ({ t, phoneNumber }) => {
  return (
    <div
      className="position-fixed"
      style={{ top: '50%', right: 0, transform: 'translateY(-50%)', zIndex: 10 }}
    >
      <a href="tel:028 3636 5940" className="d-block mt-2">
        <img src={phone} alt="" width="62" />
      </a>
    </div>
  );
};

export default withTranslation()(SocialIcons);
