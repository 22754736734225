import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { subscribeURI, uploadCVURI } from '../apis';
import { setValue } from './messageSlice';
// First, create the thunk
export const sendSubscribe = createAsyncThunk(
  'subscribe/upload',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(subscribeURI, data);
      dispatch(setValue({ show: true, text: 'Submitted successfully' }));

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
const initialState = {
  saving: 'idle',
  dirty: false,
  full_name: '',
  email: '',
  phone_number: '',
  block_id: '',
};
// Then, handle actions in your reducers:
const cvSlice = createSlice({
  name: 'subscribe',
  initialState,
  reducers: {
    onChange: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
  extraReducers: {
    [sendSubscribe.pending]: (state, action) => {
      if (state.saving === 'idle') {
        state.saving = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    // Add reducers for additional action types here, and handle saving state as needed
    [sendSubscribe.fulfilled]: (state, action) => {
      return initialState;
    },
    [sendSubscribe.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.saving === 'pending' && state.currentRequestId === requestId) {
        state.saving = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});
export const { onChange } = cvSlice.actions;
export default cvSlice.reducer;
