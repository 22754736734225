/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/react-in-jsx-scope */
import { Fragment, lazy, Suspense, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import routes from './config';
import Header from '../components/Header';
import Footer from '../components/Footer';
import OurPartners from '../components/OurPartners';
import OurPartnersContent from '../contents/OurPartnersContent.json';
import ContactUsBanner from '../components/ContactUsBanner';
import SocialIcons from '../components/SocialIcons';
import { fetchPartners } from '../reducers/partnersSlice';
import { fetchContact } from '../reducers/contactSlice';

const Router = () => {
  const dispatch = useDispatch();
  const partners = useSelector(state => state.partners);
  const contact = useSelector(state => state.contact);

  useEffect(() => {
    dispatch(fetchPartners());
    dispatch(fetchContact());
  }, []);

  return (
    <>
      <Header />
      <Suspense
        fallback={
          <div className="vh-100 text-center justify-content-center align-items-center d-flex">
            <Spinner animation="border" variant="warning" />
          </div>
        }
      >
        <Switch>
          {routes.map(routeItem => {
            return (
              <Route
                key={routeItem.component}
                path={routeItem.path}
                exact={routeItem.exact}
                component={lazy(() => import(`../pages/${routeItem.component}`))}
              />
            );
          })}
        </Switch>
      </Suspense>
      <OurPartners title={OurPartnersContent.title} companyLogos={partners.entities} />
      <ContactUsBanner />
      <SocialIcons phoneNumber={contact?.data?.phone} />
      <Footer />
    </>
  );
};

export default Router;
