export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateSpecialCharacter = text => {
  const re = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return !re.test(String(text).toLowerCase());
};

export const validatePhoneNumber = text => {
  const re = /^\d+$/;
  return re.test(String(text).toLowerCase());
};
