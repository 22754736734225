import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchJobDetailURI, fetchServiceDetailURI } from '../apis';

// First, create the thunk
export const fetchServiceDetail = createAsyncThunk(
  'services/fetchServiceDetail',
  async (id, thunkAPI) => {
    const response = await axios.get(fetchServiceDetailURI(id));
    return response.data;
  },
);

// Then, handle actions in your reducers:
const sreviceDetailSlice = createSlice({
  name: 'services',
  initialState: {},
  reducers: {
    setJobDetail: (state, action) => {
      return { ...action.payload };
    },
  },
  extraReducers: {
    [fetchServiceDetail.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchServiceDetail.fulfilled]: (state, action) => {
      return { ...action.payload.data };
    },
    [fetchServiceDetail.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});
export const { setJobDetail } = sreviceDetailSlice.actions;
export default sreviceDetailSlice.reducer;
