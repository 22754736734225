/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import routers from '../../router/config';
import logo from '../../images/logo.svg';
import style from './style.module.scss';
import enIcon from '../../images/eng.svg';
import jpIcon from '../../images/jp.svg';

const languageList = {
  en: { key: 'en', icon: enIcon },
  jp: { key: 'jp', icon: jpIcon },
};
const LANGUAGE = 'language';

const Header = ({ t, i18n }) => {
  const [active, setActive] = useState(localStorage.getItem(LANGUAGE) || 'en');
  const fireEvent = (element, event) => {
    if (document.createEventObject) {
      // dispatch for IE

      const evt = document.createEventObject();
      return element.fireEvent(`on${event}`, evt);
    }

    const evt = document.createEvent('HTMLEvents');
    evt.initEvent(event, true, true);
    return !element.dispatchEvent(evt);
  };

  const changeLang = code => {
    const langSeletor = document.querySelector('.goog-te-combo');
    switch (code) {
      case 'JA': {
        const jaOption = document.querySelector('.goog-te-combo option[value="ja"]');
        if (jaOption != null) {
          jaOption.selected = true;
          fireEvent(langSeletor, 'change');
        }
        break;
      }
      case 'EN': {
        const enOption = document.querySelector('.goog-te-combo option[value="en"]');
        if (enOption != null) {
          enOption.selected = true;
          fireEvent(langSeletor, 'change');
          setTimeout(() => {
            enOption.selected = true;
            fireEvent(langSeletor, 'change');
          }, 3000);
        }
        break;
      }
      default:
        break;
    }
  };

  const changeLanguage = lang => {
    i18n.changeLanguage(lang);
    localStorage.setItem(LANGUAGE, lang);
    setActive(lang);

    changeLang(lang === 'en' ? 'EN' : 'JA');
  };

  return (
    <Navbar bg="light" expand="lg" className={style.header}>
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand>
          <img src={logo} alt="logo" />
        </Navbar.Brand>

        <Navbar.Collapse id="basic-navbar-nav" className={style.collapse} translate="no">
          <Nav className="ml-auto">
            {routers?.map(item => {
              if (!item.isMenu) return '';
              return (
                <Nav.Link
                  className={style.menu_item}
                  as={NavLink}
                  to={item?.path}
                  exact={item?.exact}
                  key={item?.path}
                >
                  {t(item?.name)}
                </Nav.Link>
              );
            })}
          </Nav>
        </Navbar.Collapse>
        <Nav className={style.language} translate="no">
          <NavDropdown
            title={<img src={languageList[active]?.icon || enIcon} alt={active} />}
            className={cn('ml-lg-3 mr-lg-5', style.menu_language)}
          >
            {Object.keys(languageList).map((key, index) => {
              const item = languageList[key];
              return (
                <NavDropdown.Item
                  eventKey={index + 1}
                  key={index}
                  onClick={() => changeLanguage(item.key)}
                >
                  <img src={item?.icon} alt={item.key} />
                </NavDropdown.Item>
              );
            })}
          </NavDropdown>
        </Nav>

        <div id="google_translate_element" style={{ display: 'none' }} />
      </Container>
    </Navbar>
  );
};

export default withTranslation()(Header);
