import { configureStore } from '@reduxjs/toolkit';
import categories from './reducers/categoriesSlice';
import jobs from './reducers/jobsSlice';
import blocks from './reducers/blocksSlice';
import jobDetail from './reducers/jobDetailSlice';
import cv from './reducers/cvSlice';
import message from './reducers/messageSlice';
import contact from './reducers/contactSlice';
import sendContact from './reducers/sendContactSlice';
import bods from './reducers/bodSlice';
import relatedJobs from './reducers/relatedJobsSlice';
import subscribe from './reducers/subscribeSlice';
import news from './reducers/newsSlice';
import mainNews from './reducers/mainNewsSlice';
import popularNews from './reducers/popularNewsSlice';
import newsDetail from './reducers/newsDetailSlice';
import comments from './reducers/commentsSlice';
import sendComment from './reducers/sendCommentSlice';
import services from './reducers/servicesSlice';
import serviceDetail from './reducers/serviceDetailSlice';
import homepage from './reducers/homepageSlice';
import banners from './reducers/bannersSlice';
import hotJobs from './reducers/hotJobsSlice';
import partners from './reducers/partnersSlice';
import introduces from './reducers/introducesSlice';
import customers from './reducers/customerSlice';

export default configureStore({
  reducer: {
    categories,
    jobs,
    jobDetail,
    cv,
    message,
    contact,
    bods,
    blocks,
    relatedJobs,
    subscribe,
    news,
    mainNews,
    popularNews,
    newsDetail,
    comments,
    sendComment,
    services,
    serviceDetail,
    homepage,
    banners,
    hotJobs,
    sendContact,
    partners,
    introduces,
    customers,
  },
});
