/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchBlocksURI, fetchJobsURI, fetchNewsURI } from '../apis';

// First, create the thunk
export const fetchMainNews = createAsyncThunk(
  'news/fetchMainNews',
  async (params = {}, thunkAPI) => {
    const { page = 1, limit = 4, type } = params;
    const response = await axios.get(`${fetchNewsURI}`, {
      params: {
        page,
        limit,
        type: 'main_post',
      },
    });
    return response.data;
  },
);

// Then, handle actions in your reducers:
const jobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    entities: [],
    loading: 'idle',
    saving: 'idle',
    currentRequestId: undefined,
    error: null,
    currentPage: 1,
    totalPage: 1,
    total: 0,
  },
  reducers: {
    onChange: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
  extraReducers: {
    [fetchMainNews.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchMainNews.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        const { data, last_page, current_page, total } = action.payload.data;
        state.loading = 'idle';
        state.entities = data;
        state.currentPage = current_page;
        state.totalPage = last_page;
        state.currentRequestId = undefined;
        state.total = total;
      }
    },
    [fetchMainNews.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});
export const { onChange } = jobsSlice.actions;

export default jobsSlice.reducer;
