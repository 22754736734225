/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchHomepageURI, getContactURI } from '../apis';

// First, create the thunk
export const fetchContact = createAsyncThunk(
  'contact/fetchContact',
  async (params = {}, thunkAPI) => {
    const response = await axios.get(`${getContactURI}`);
    return response.data;
  },
);

// Then, handle actions in your reducers:
const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    data: {},
    loading: 'idle',
    saving: 'idle',
    currentRequestId: undefined,
    error: null,
  },
  reducers: {
    onChange: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
  extraReducers: {
    [fetchContact.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchContact.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.data = action.payload.data;
        state.currentRequestId = undefined;
      }
    },
    [fetchContact.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});
export const { onChange } = contactSlice.actions;

export default contactSlice.reducer;
