import { createSlice } from '@reduxjs/toolkit';

export const MessageSlice = createSlice({
  name: 'message',
  initialState: {
    show: false,
    body: '',
    text: '',
  },
  reducers: {
    setShow: state => {
      state.show = true;
    },
    setValue: (state, action) => {
      return { ...state, ...action.payload };
    },
    setHide: state => {
      state.show = false;
    },
  },
});
export const { setShow, setHide, setValue } = MessageSlice.actions;

export default MessageSlice.reducer;
