const routes = [
  {
    path: '/',
    exact: true,
    component: 'Home',
    name: 'home',
    isMenu: true,
  },
  {
    path: '/about-us',
    exact: true,
    component: 'AboutUs',
    name: 'about us',
    isMenu: true,
  },
  {
    path: '/about-us/customer',
    exact: true,
    component: 'Customer',
    name: 'Customer',
    isMenu: false,
  },
  {
    path: '/services',
    exact: true,
    component: 'Services',
    name: 'service',
    isMenu: true,
  },
  {
    path: '/recruitment',
    exact: true,
    component: 'Recruitment',
    name: 'recruitment',
    isMenu: true,
  },
  {
    path: '/news',
    exact: true,
    component: 'News',
    name: 'news',
    isMenu: true,
  },
  {
    path: '/news/detail/:id',
    exact: true,
    component: 'NewsDetail',
    name: 'NewsDetail',
    isMenu: false,
  },
  {
    path: '/contact',
    exact: true,
    component: 'Contact',
    name: 'contact',
    isMenu: true,
  },
  {
    path: '/recruitment/detail/:id',
    exact: true,
    component: 'RecruitmentDetail',
    name: 'RecruitmentDetail',
    isMenu: false,
  },
  {
    path: '/services/detail/system-development',
    exact: true,
    component: 'SystemDevelopment',
    name: 'SystemDevelopment',
    isMenu: false,
  },
  {
    path: '/services/detail/marketing',
    exact: true,
    component: 'Marketing',
    name: 'Marketing',
    isMenu: false,
  },
  {
    path: '/services/detail/offshore-development',
    exact: true,
    component: 'OffshoreDevelopment',
    name: 'OffshoreDevelopment',
    isMenu: false,
  },
  {
    path: '/services/detail/staffing',
    exact: true,
    component: 'Staffing',
    name: 'Staffing',
    isMenu: false,
  },
  {
    path: '/services/detail/internet-of-things',
    exact: true,
    component: 'InternetOfThings',
    name: 'InternetOfThings',
    isMenu: false,
  },
  {
    path: '/services/detail/lft',
    exact: true,
    component: 'LFT',
    name: 'lft',
    isMenu: false,
  },
  {
    path: '/services/detail/company-establishment',
    exact: true,
    component: 'CompanyEstablishment',
    name: 'CompanyEstablishment',
    isMenu: false,
  },
  {
    path: '/services/detail/investment-consulting',
    exact: true,
    component: 'InvestmentConsulting',
    name: 'InvestmentConsulting',
    isMenu: false,
  },
  {
    path: '/services/detail/:id',
    exact: true,
    component: 'OtherService',
    name: 'OtherService',
    isMenu: false,
  },
  {
    path: '/staffing-service',
    exact: true,
    component: 'StaffingService',
    name: 'StaffingService',
    isMenu: false,
  },
  {
    path: '/iot-letsconnect',
    exact: true,
    component: 'IOTLetsConnect',
    name: 'IOTLetsConnect',
    isMenu: false,
  },
  {
    path: '/cloud-server',
    exact: true,
    component: 'CloudServer',
    name: 'CloudServer',
    isMenu: false,
  },
  {
    path: '/marketing',
    exact: true,
    component: 'MarketingPdf',
    name: 'MarketingPdf',
    isMenu: false,
  },
];

export default routes;
