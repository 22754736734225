import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchJobDetailURI, fetchNewsDetailURI } from '../apis';

// First, create the thunk
export const fetchNewsDetail = createAsyncThunk('jobs/fetchNewsDetail', async (id, thunkAPI) => {
  const response = await axios.get(fetchNewsDetailURI(id));
  return response.data;
});

// Then, handle actions in your reducers:
const jobDetailSlice = createSlice({
  name: 'news',
  initialState: {},
  reducers: {
    setJobDetail: (state, action) => {
      return { ...action.payload };
    },
  },
  extraReducers: {
    [fetchNewsDetail.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchNewsDetail.fulfilled]: (state, action) => {
      return { ...(action.payload.data[0] || action.payload.data) };
    },
    [fetchNewsDetail.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});
export const { setJobDetail } = jobDetailSlice.actions;
export default jobDetailSlice.reducer;
