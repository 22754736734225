/* eslint-disable react/react-in-jsx-scope */
import {
  Row,
  Col,
  InputGroup,
  Button,
  FormControl,
  Form,
  Container,
  Image,
  Nav,
} from 'react-bootstrap';
import { MdEmail, MdPhoneInTalk } from 'react-icons/md';
import { IoLocationSharp } from 'react-icons/io5';
import { FaChevronRight, FaFacebookF, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import style from './style.module.scss';
import commonStyle from '../../common.module.scss';
import logo from '../../images/logo.svg';
import routers from '../../router/config';
import { validateEmail } from '../../utils/helper';
import { sendSubscribe } from '../../reducers/subscribeSlice';

const Footer = ({ t, i18n }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState({ value: null, message: '' });
  const onSubmit = async () => {
    if (email.message) {
      return;
    }
    const data = new FormData();
    data.append('email', email.value);

    await dispatch(sendSubscribe(data));
    setEmail({ value: '' });
  };
  return (
    <Container>
      <Row>
        <Col lg="4" xs="12" className="text-lg-left text-center mb-lg-0 mb-5">
          <Image src={logo} height="110px" />

          <p className={cn('d-sm-block d-md-none mt-2 text-uppercase', commonStyle)}>
            Nichietsu provides a wide range of services related to the system.
          </p>
          <div className="ml-lg-1 mt-3">
            <a href="https://www.facebook.com/vnnichietsu">
              <FaFacebookF className={cn(style.rounded_circle, 'ml-2')} />
            </a>
            <a href="https://www.linkedin.com/company/nichietsu/">
              <FaLinkedinIn className={cn(style.rounded_circle, 'ml-2')} />
            </a>
            <a href="https://www.youtube.com/channel/UCi19R-H6poQpw_6366eSVYw">
              <FaYoutube className={cn(style.rounded_circle, 'ml-2')} />
            </a>
          </div>
        </Col>

        <Col lg="2" xs="12">
          <div className="text-uppercase text-warning font-weight-bold">{t('Company')}</div>
          {routers?.map(item => {
            if (!item.isMenu) return '';
            return (
              <a
                className="mt-3 d-block text-capitalize text-dark"
                href={item?.path}
                key={item?.path}
              >
                {t(item?.name)}
              </a>
            );
          })}
        </Col>
        <Col lg="3" xs="12" className="mt-lg-0 mt-3">
          <div className="text-uppercase text-warning font-weight-bold">{t('Contact')}</div>
          <div className="mt-3">
            <MdEmail size="24" />
            <span className="ml-1">info@nichietsuvn.com</span>
          </div>
          <div className="mt-3">
            <MdPhoneInTalk size="24" />
            <span className="ml-1">(+84) 28 3636 5940</span>
          </div>
          <div className="mt-3 d-flex">
            <div>
              <IoLocationSharp size="24" />
            </div>
            <div className="ml-1">
              <b>VIETNAM</b>
              <p>
                Nichietsu Building, 68-70 Nguyen Ngoc Phuong, Ward 19, Binh Thanh District, Ho Chi
                Minh City.
              </p>
              <b>JAPAN</b>
              <p>Shinso Building. 5F, 5-68-4 Otamachi Naka-ku, Yokohama-shi, Kanagawa-ken</p>
            </div>
          </div>
        </Col>
        <Col lg="3" xs="12" className="mt-lg-0 mt-3">
          <div className="text-uppercase text-warning font-weight-bold">{t('SUBSCRIBE')}</div>
          <div className="mt-3">{t('Follow our newsletter to stay updated about us')}</div>
          <div className="mt-3">
            <div>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder={t('Email Address')}
                  aria-label="Email Address"
                  className={style.email_input}
                  isInvalid={email.message}
                  value={email.value}
                  onChange={e => {
                    const { value } = e.target;
                    if (!value) {
                      return setEmail({ value, message: 'Please input email' });
                    }
                    if (!validateEmail(value)) {
                      return setEmail({ value, message: 'Wrong format' });
                    }
                    return setEmail({ value, message: '' });
                  }}
                />

                <InputGroup.Append>
                  <Button variant="warning" className="text-white" onClick={onSubmit}>
                    <FaChevronRight />
                  </Button>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">{email.message}</Form.Control.Feedback>
              </InputGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-5 text-center">
        <Col>
          <p className={style.copy_writer}> © 2021 NICHIETSU</p>
        </Col>
      </Row>
    </Container>
  );
};
export default withTranslation()(Footer);
