/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/react-in-jsx-scope */
import { Container, Row, Col, Image } from 'react-bootstrap';
import style from './style.module.scss';

const OurPartners = ({ title, companyLogos }) => {
  return (
    <Container className="mt-5 mb-5" translate="no">
      <Row>
        <Col className="text-secondary text-uppercase text-center mb-3">{title}</Col>
      </Row>
      <Row>
        {companyLogos?.map((item, index) => {
          return (
            <Col lg="3" xs="12" key={index} className="mb-3">
              <Image className={style.image_style} src={item} thumbnail />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default OurPartners;
