export const hostName = 'http://localhost';
export const port = ':8000';
export const publicURI = `${process.env.REACT_APP_API_BASE_URL}/user`;

export const fetchHomepageURI = `${publicURI}/getIntroHomePage`;
export const fetchCategoriesURI = `${publicURI}/categories`;
export const fetchJobsURI = `${publicURI}/jobs`;
export const fetchCustomersURI = `${publicURI}/getOurCustomer`;
export const fetchBannersURI = `${publicURI}/configs/getBanner`;
export const fetchPartnersURI = `${publicURI}/configs/getOurPartner`;
export const fetchServicesURI = `${publicURI}/services_categories`;
export const fetchCommentsURI = `${publicURI}/comment`;
export const fetchNewsURI = `${publicURI}/news`;
export const fetchBlocksURI = `${publicURI}/blocks`;
export const fetchIntroducesURI = `${publicURI}/introductions`;
export const fetchJobDetailURI = id => `${publicURI}/jobs/${id}`;
export const fetchNewsDetailURI = id => `${publicURI}/news/${id}`;
export const fetchServiceDetailURI = id =>
  `${publicURI}/services_categories/getDetailCategoryService/${id}`;

export const uploadCVURI = `${publicURI}/cv/upload`;
export const sendCommentURI = `${publicURI}/comment`;
export const subscribeURI = `${publicURI}/candidates`;
export const contactURI = `${publicURI}/contacts`;
export const getContactURI = `${publicURI}/configs/getContact`;
export const bodsURI = `${publicURI}/bods`;
