import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { contactURI } from '../apis';
import { setValue } from './messageSlice';
// First, create the thunk
export const postContact = createAsyncThunk(
  'contact/postContact',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(contactURI, data);
      dispatch(
        setValue({
          show: true,
          text: 'Thank you for your message. It has been sent.',
        }),
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
const initialState = {
  saving: 'idle',
  dirty: false,
  name: '',
  email: '',
  message: '',
};
// Then, handle actions in your reducers:
const sendContact = createSlice({
  name: 'sendContact',
  initialState,
  reducers: {
    onChange: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
  extraReducers: {
    [postContact.pending]: (state, action) => {
      state.saving = 'pending';
      state.currentRequestId = action.meta.requestId;
    },
    // Add reducers for additional action types here, and handle saving state as needed
    [postContact.fulfilled]: (state, action) => {
      return initialState;
    },
    [postContact.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});
export const { onChange } = sendContact.actions;
export default sendContact.reducer;
